<template>
  <div class="blogs">
    <v-card flat>
      <v-toolbar flat dense>
        <v-toolbar-title>{{ _decode(title) }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          text
          x-small
          color="primary"
          @click.stop="$set(editDialog, _decode(id), true)"
        >
          edit
        </v-btn>
        <PageEdit
          v-if="editDialog[_decode(id)]"
          :myId="id"
          :myTitle="title"
          :editDialog="editDialog[_decode(id)]"
          @closeEdit="closeEdit"
          @submitEdit="submitEdit"
        />

        <v-btn
          text
          x-small
          color="error"
          @click.stop="$set(removeDialog, _decode(id), true)"
        >
          delete
        </v-btn>
        <PageRemove
          v-if="removeDialog[_decode(id)]"
          :myId="id"
          :myTitle="title"
          :removeDialog="removeDialog[_decode(id)]"
          @closeRemove="closeRemove"
          @submitRemove="submitRemove"
        />

        <div
          class="blog_status"
          :style="{ color: blogPost.publish ? 'blue' : 'red' }"
        >
          {{ blogPost.publish ? "published" : "not published" }}
        </div>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text v-resize="onResize" style="padding: 0">
        <div
          class="content scrollable"
          v-html="blogPost.details"
          :style="{ height: panheight }"
        ></div>
      </v-card-text>
    </v-card>
    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "@/mixins/utils.js";
import PageEdit from "../../../components/blogs/edit.vue";
import PageRemove from "../../../components/blogs/delete.vue";

export default {
  props: {
    id: { type: String, required: true },
    title: { type: String, required: true },
  },
  components: { PageEdit, PageRemove },
  mixins: [Utils],
  data: () => ({
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      text: "Processing ... ",
    },
    blogPost: {},
    editDialog: {},
    removeDialog: {},
  }),
  watch: {
    id: function (a) {
      let me = this._decode(a);
      this.find(me);
    },
  },
  computed: {
    panheight: function () {
      let w = this.windowSize.x;
      console.log("w", w);
      if (w > 450) {
        let h = this.windowSize.y - 175 + "px";
        return h;
      } else {
        return "400px";
      }
    },
  },
  created() {
    let me = this._decode(this.id);
    this.find(me);
  },
  methods: {
    find(id) {
      this.loading = true;
      let self = this;
      Restful.blogs
        .find(id)
        .then((response) => {
          console.log(response.data);
          this.blogPost = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.loading = false;
        });
    },
    closeEdit(p) {
      console.log(p);
      this.$set(this.editDialog, p.id, p.state);
    },
    submitEdit(p) {
      console.log(p);

      this.snack.bar = true;
      let self = this;
      Restful.blogs
        .update(p.id, p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.find(p.id);
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });

      this.$set(this.editDialog, p.id, p.state);
    },
    closeRemove(p) {
      console.log(p);
      this.$set(this.removeDialog, p.id, p.state);
    },
    submitRemove(p) {
      console.log(p);
      this.snack.bar = true;
      let self = this;
      Restful.blogs
        .delete(p.id)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.find(p.id);
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.removeDialog, p.id, p.state);
    },
  },
};
</script>
